/* wsmenu-list nahrazeno wsmenu__list BEM */
/* zmena na less */
/* odstraneny prefixy */
/* POUZE PRO MENU BEZ HOVER SUBMENU */
/* ======== Find Below Table of Content Points to Go Relevant Section  =========
[Table of contents DESKTOP]
Desktop Base CSS
 - *ODSTRANENO
Desktop Main Menu CSS
- *PROCISTENO
Desktop Drop Down Menu CSS
 - *ODSTRANENO
Desktop Mega Menus CSS
 - *ODSTRANENO
-> Desktop Half Menu CSS
 - *ODSTRANENO
-> Desktop HTML Form Menu CSS
 - *ODSTRANENO
Desktop Extra CSS
 - *ODSTRANENO

[Table of contents MOBILE ]
Mobile Menu Change Brake Point
- *PROCISTENO (MEGAMENU, SUBMENU)
Mobile Header CSS
 - *ODSTRANENO
Mobile Main Menu CSS
- *PROCISTENO
Mobile Slide Down Links CSS
 - *ODSTRANENO
Mobile Mega Menus CSS
 - *ODSTRANENO
Mobile Overlay/Drawer CSS
 - *ODSTRANENO
Mobile Sub Menu Expander Arrows
 - *ODSTRANENO
Mobile Extra CSS
===============================================================================*/
/*Label None*/
.wsdown-menuopner {
  display: none;
}
.wsdownopener {
  display: none;
}
.wsdownopener-sub {
  display: none;
}
.wsdexpander {
  display: none;
}
/*Label None*/
.wsmenu__list > li > a {
  position: relative;
  display: block;
}
/*==============================================================================
                              Start Mobile CSS
===============================================================================*/
/* ================== Mobile Menu Change Brake Point ================== */
@media only screen and (max-width: 899px) {
  /* Show Menu in Mobile */
  .wsdownopener-sub {
    display: block;
  }
  /*Main UL*/
  .wsmenu > input:checked + .wsmenu__list {
    max-height: 1000px;
  }
  .wsdownopener {
    display: block;
    width: 100%;
  }
  /* ================== Mobile Main Menu CSS ================== */
  .wsmenu > .wsmenu__list {
    float: none;
    width: 100%;
    max-height: 0;
    overflow: hidden;
    list-style: none;
    transition: max-height 1s;
  }
  .wsmenu > .wsmenu__list > li {
    display: block;
    width: 100%;
  }
}
/*# sourceMappingURL=css/menu-lib.css.map */